@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');

*{
  margin: 0;
  padding:0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: ebson;
  src: url(./assets/ebson.ttf);
}

@font-face{
  font-family: leoprid;
  src: url(./assets/Leporid-Regular.ttf);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.intro {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

.intro.animate {
  /* Animation styles when the 'animate' class is present */
  opacity: 1;
  transform: translateY(0);
}

.under, .mask{
  height:100%;
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  cursor:default;
  padding:64px;
}

span{
  color:brown;
}

.mask{
  position:absolute;
  top:10;
  mask-image:url('./assets/icons/dot.svg');
  background-color: brown;
  mask-repeat: no-repeat;
  mask-size: 40px;
  color:aliceblue;
  -webkit-mask-image: url('./assets/icons/dot.svg');
  -webkit-mask-repeat: no-repeat;
}

