body {
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
  
  .menu-item {
    position: absolute;
    opacity:100%;
    top: 50%;
    left: 50%;
    height: 6em;
    width: 6em;
    margin: -3em;
    text-align: center;
    display: flex;
    border-radius: 50%;
    box-shadow: 
      10px 10px 10px -11px 
      rgba(0,0,0,0.3);
    cursor: pointer;
  }
    .menu-item img {
      color: white;
      font-size: 2em;
      display: flex;
      margin: 0.5em;
      transition: font-size .1s ease-out;
    }
    .menu-item:hover {
      height: 7em;
      width: 7em;
      margin: -3.5em;
    }
    .menu-item img:hover {
        font-size: 2.5em;
      }
    

  .item-show {
    opacity: 1;
    transition: 
      height .1s ease-out,
      width .1s ease-out,
      margin .1s ease-out,
      opacity .5s ease-out;
  }
  
  .item-hide {
    opacity: 0;
  }
  
.menu-background {
height: 100%;
width: 100%;
position: absolute;
z-index: 100;
background-color: #ffffff;

}
  
  .button-bg {
    position: relative;
    height: 18em;
    width: 18em;
    border-radius: 50%;
    margin: 1.75em auto 0;
    z-index: 1000;
    top: 50%;
    margin-top: -9em;
  }
  
  .menu-toggle {
    position: absolute;
    z-index: 2000;
    margin-top: 2em;
    right:2em;
    height: 5em;
    width: 5em;
    border-radius: 50%;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
    i {
      font-size: 20px;
    }
  
  
  .toggle-open {
    border: 3px solid #000;
    color: #000;
    padding:10px;
    top: 50%;
    left: 50%;
    margin-top: -2.5em;
    margin-left: -2em;
    animation: fadeIn 1s 1 ease-in;
  }
  
  .toggle-closed {
    border: 3px solid #000;
    padding:10px;
    color: #000;
  }
  
  .menu-wrapper-open {
    opacity: 1;
    visibility: visible;
    transition: all .2s ease-out;
  }
  
  .menu-wrapper-closed {
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-out;
  }
  
  .animate-menu {
    animation:
      spin 1.3s 1 
      cubic-bezier(
        0.175, 0.585, 0.08, 1.2
      );
    animation-delay: .2s
  }

  
  
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
  
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  
  
  